<template>
  <footer class="website-footer">
    <div class="website-footer-body">
      <div class="website-footer-body-content">
        <a href="#" @click.prevent="openMailTo" class="website-footer-body-content-item">support@oxia.org</a>
        <div @click="showModal" class="website-footer-body-content-item" style="cursor: pointer;">
          Terms & Conditions / Privacy Policy
        </div>
        <p class="website-footer-body-content-item copy-oxia">&copy; 2024 OXIA DM</p>
      </div>
    </div>
    <OModal v-model="isModalActive" isCloseable title="Book Your Appointment Pop Up" class="terms-and-conditions-modal">
      <div class="terms-and-conditions-modal-content">
        <div class="terms-and-conditions-page">
          <div class="terms-and-conditions-page-header">
            <h1 class='terms-and-conditions-page-header-text'>Terms & Conditions of Using our Website</h1>
          </div>
          <div class="terms-and-conditions-page-body">
            <p>Use of this site automatically constitute acceptance of our Terms and Conditions and Privacy Policy.
            </p>

            <br>

            <p>1. CONDITIONS OF USE</p>

            <br>

            <p>On this site the use of the term “Oxia DM (Pty) Ltd” refers its electronic mail services, web services,
              associates & its management or any other means at the time all legally affiliated within Oxia DM (Pty)
              Ltd. By using this site you automatically agree to acknowledge this.</p>

            <br>

            <p>We reserve the right to make changes to our site, policies, and these Conditions of Use at any time. If
              any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition
              shall be deemed severable and shall not affect the validity and enforceability of any remaining
              condition.</p>

            <br>

            <p>Oxia DM (Pty) Ltd and its associates provide their services to you subject to the following conditions.
              If you visit or download from this website, you automatically constitute acceptance to these Terms &
              Conditions and Privacy Policy stated on this site. Please read them carefully.</p>

            <br>

            <p>Although Oxia DM (Pty) Ltd will according to its organisational abilities try and make this site as
              user-friendly & simple as easy to use, you shall however accept the following: The use of this site is
              at own risk and you shall accept that Oxia DM (Pty) Ltd cannot be held liable for your mismanagement and
              or inability to efficiently & effectively use this site in whatsoever way possible.</p>

            <br>

            Country of Domicile Policy & Applicable Law:

            <br>

            <p>TERRITORIAL BORDERS</p>

            <br>

            <p>By visiting Oxia DM (Pty) Ltd, you agree that the laws of South Africa, without regard to principles of
              conflict of laws, will govern these Conditions of Use and any dispute of any sort that might arise
              between you and Oxia DM (Pty) Ltd or its associates.</p>

            <br>

            <p>At present moment this site is controlled and operated from the Republic of South Africa and therefore,
              applicable with the aforesaid, governed by South African law.<br><br></p>


            <p>DISPUTES</p>

            <br>

            <p>Any dispute relating in any way to your visit to Oxia DM (Pty) Ltd, shall be submitted to confidential
              arbitration in South Africa, except that, to the extent you have in any manner violated or threatened to
              violate Oxia DM (Pty) Ltd intellectual property rights, Oxia DM (Pty) Ltd may seek injunctive or other
              appropriate relief in any magistrates court in the South Africa and you consent to exclusive
              jurisdiction and venue in such courts.</p>

            <br>

            <p>2. PRIVACY POLICY</p>

            <br>

            <p>Please review our Privacy Notice, which also governs your visit to our website, to understand our
              practices.</p>

            <br>

            <p>OVERVIEW</p>

            <br>

            <p>These terms of use govern your use of our website; by using our website, you agree to these terms of
              use in full. If you disagree with these terms of use or any part of these terms of use, you must not use
              our website.</p>

            <br>

            <p>You must be at least 18 years of age to use our website. By using our website and by agreeing to these
              terms of use, you warrant and represent that you are at least 18 years of age.</p>

            <br>

            <p>Our website uses cookies. By using our website and agreeing to these terms of use, you consent to our
              use of cookies in accordance with the terms of our privacy policy / cookies policy.</p>

            <br>

            <p>LICENCE TO USE WEBSITE</p>

            <br>

            <p>Oxia DM (Pty) Ltd grants you a limited license to access and make personal use of this site and not to
              download (other than page caching) or modify it, or any portion of it, except with express written
              consent of Oxia DM (Pty) Ltd. This license does not include any resale or commercial use of this site or
              its contents.</p>

            <br>

            <p>Unless otherwise stated, we or our licensors own the intellectual property rights in the website and
              material on the website. Subject to the licence below, all these intellectual property rights are
              reserved.</p>

            <br>

            <p>You may view, download for caching purposes only, and print pages from the website for your own
              personal use, subject to the restrictions set out below and elsewhere in these terms of use.</p>

            <br>

            <p>You must not:</p>

            <br>

            <ul class="terms-and-conditions-page-list">
              <li>Republish material from this website (including republication on another website);</li>
              <li>Sell, rent, or sub-license material from the website;</li>
              <li>Show any material from the website in public;</li>
              <li>Reproduce, duplicate, copy, or otherwise exploit material on our website for a commercial purpose;
              </li>
              <li>Edit or otherwise modify any material on the website; or</li>
              <li>Redistribute material from this website, except for content specifically and expressly made
                available for redistribution.</li>
            </ul>

            <p>Where content is specifically made available for redistribution, it may only be redistributed within
              our scheme (such as to other residents).</p>

            <br>

            <p>ACCEPTABLE USE</p>

            <br>

            <p>You must not use our website in any way that causes, or may cause, damage to the website or impairment
              of the availability or accessibility of the website; or in any way which is unlawful, illegal,
              fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or
              activity.</p>

            <br>

            <p>You must not use our website to copy, store, host, transmit, send, use, publish or distribute any
              material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke
              logger, rootkit or other malicious computer software.</p>

            <br>

            <p>You must not conduct any systematic or automated data collection activities (including, without
              limitation, scraping, data mining, data extraction and data harvesting) on or in relation to our website
              without our express written consent. You must not use our website to transmit or send unsolicited
              commercial communications. You must not use our website for any purposes related to marketing without
              our express written consent.</p>

            <br>

            <p>You may not use any meta tags or any other "hidden text" utilising Oxia DM (Pty) Ltd name or trademarks
              without the express written consent of Oxia DM (Pty) Ltd. Any unauthorised use terminates the permission
              or license granted by Oxia DM (Pty) Ltd. You are granted a limited, revocable, and nonexclusive right to
              create a hyperlink to the home page of Oxia DM (Pty) Ltd so long as the link does not portray Oxia DM
              (Pty) Ltd, its associates, or their services in a false, misleading, derogatory, or otherwise offensive
              matter. You may not use any of Oxia DM (Pty) Ltd logo or other proprietary graphic or trademark as part
              of the link without express written permission.</p>

            <br>

            <p>RESTRICTED ACCESS</p><br>

            <p>Access to certain areas of our website is restricted. We reserve the right to restrict access to other
              areas of our website, or indeed our whole website, at our discretion.</p>

            <br>

            <p>If you generate a username and password to enable you to access restricted areas of our website or
              other content or services, you must ensure that the password is kept confidential.</p>

            <br>

            <p>You must notify us in writing immediately if you become aware of any unauthorised use of your account
              or password.</p>

            <br>

            <p>You are responsible for any activity on our website arising out of any failure to keep your password
              confidential and may be held liable for any losses arising out of such a failure.</p>

            <br>

            <p>You must not use any other person’s username and password to access our website, unless you have that
              person’s express permission to do so.</p>

            <br>

            <p>We may disable your username and password at any time in our sole discretion with or without notice or
              explanation.</p>

            <br>

            <p>USER CONTENT</p><br>

            <p>In these terms of use, “your content” means material (including, without limitation, text, images,
              audio material, video material and audio-visual material) that you submit to our website, for whatever
              purpose.</p>

            <br>

            <p>You grant to us a worldwide, irrevocable, non-exclusive, royalty-free licence to use, reproduce, adapt,
              publish, translate and distribute your content in any existing or future media. You also grant to us the
              right to sub-license these rights and the right to bring an action for infringement of these rights.</p>

            <br>

            <p>You warrant and represent that your content will comply with these terms of use.</p>

            <br>

            <p>Your content must not be illegal or unlawful, must not infringe any third party’s legal rights and must
              not be capable of giving rise to legal action whether against you or us or a third party (in each case
              under any applicable law).</p>

            <br>

            <p>You must not submit any content to the website that is or has ever been the subject of any threatened
              or actual legal proceedings or other similar complaint.</p>

            <br>

            <p>We reserve the right to edit or remove any material submitted to our website, or stored on our servers,
              or hosted or published upon our website.</p>

            <br>

            <p>Notwithstanding our rights under these terms of use in relation to your content, we do not undertake to
              monitor the submission of such content to, or the publication of such content on, our website.</p>

            <br>

            <p>LIMITED WARRANTIES</p><br>

            <p>We do not warrant the completeness or accuracy of the information published on this website; nor do we
              commit to ensuring that the website remains available or that the material on the website is kept up to
              date.</p>

            <br>

            <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and
              conditions relating to this website and the use of this website (including, without limitation, any
              warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of
              reasonable care and skill).</p>

            <br>

            <p>LIMITATIONS AND EXCLUSIONS OF LIABILITY</p>

            <br>

            <p>Nothing in these terms of use will: (a) limit or exclude our or your liability for death or personal
              injury resulting from negligence; (b) limit or exclude our or your liability for fraud or fraudulent
              misrepresentation; (c) limit any of our or your liabilities in any way that is not permitted under
              applicable law; or (d) exclude any of our or your liabilities that may not be excluded under applicable
              law.</p>

            <br>

            <p>The limitations and exclusions of liability set out in this Section and elsewhere in these terms of
              use: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under these
              terms of use or in relation to the subject matter of these terms of use, including liabilities arising
              in contract, in tort (including negligence) and for breach of statutory duty.</p>

            <br>

            <p>To the extent that the website and the information and services on the website are provided free of
              charge, we will not be liable for any loss or damage of any nature.</p>

            <br>

            <p>We will not be liable to you in respect of any losses arising out of any event or events beyond our
              reasonable control.</p>

            <br>

            <p>We will not be liable to you in respect of any business losses, including (without limitation) loss of
              or damage to profits, income, revenue, use, production, anticipated savings, business, contracts,
              commercial opportunities or goodwill.</p>

            <br>

            <p>We will not be liable to you in respect of any loss or corruption of any data, database or software. We
              will not be liable to you in respect of any special, indirect or consequential loss or damage.</p>

            <br>

            <p>INDEMNITY</p><br>

            <p>You hereby indemnify us and undertake to keep us indemnified against any losses, damages, costs,
              liabilities and expenses (including, without limitation, legal expenses and any amounts paid by us to a
              third party in settlement of a claim or dispute on the advice of our legal advisers) incurred or
              suffered by us arising out of any breach by you of any provision of these terms of use, or arising out
              of any claim that you have breached any provision of these terms of use.</p>

            <br>

            <p>BREACHES OF THESE TERMS OF USE</p>

            <br>

            <p>Without prejudice to our other rights under these terms of use, if you breach these terms of use in any
              way, we may take such action as we deem appropriate to deal with the breach, including suspending your
              access to the website, prohibiting you from accessing the website, blocking computers using your IP
              address from accessing the website, contacting your internet service provider to request that they block
              your access to the website and/or bringing court proceedings against you.</p>

            <br>

            <p>VARIATION</p>

            <br>

            <p>We may revise these terms of use from time to time. Revised terms of use will apply to the use of our
              website from the date of publication of the revised terms of use on our website.</p>

            <br>

            <p>ASSIGNMENT</p>

            <br>

            <p>We may transfer, sub-contract or otherwise deal with our rights and/or obligations under these terms of
              use without notifying you or obtaining your consent.</p>

            <br>

            <p>You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these
              terms of use.</p>

            <br>

            <p>SEVERABILITY</p>

            <br>

            <p>If a provision of these terms of use is determined by any court or other competent authority to be
              unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or
              unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be
              deemed to be deleted, and the rest of the provision will continue in effect.</p>

            <br>

            <p>EXCLUSION OF THIRD PARTY RIGHTS</p>

            <br>

            <p>These terms of use are for the benefit of you and us, and are not intended to benefit any third party
              or be enforceable by any third party. The exercise of our and your rights in relation to these terms of
              use is not subject to the consent of any third party.</p>

            <br>

            <p>ENTIRE AGREEMENT</p>

            <br>

            <p>These terms of use, together with our privacy policy, constitute the entire agreement between you and
              us in relation to your use of our website and supersede all previous agreements in respect of your use
              of our website.</p>

            <br>

            <p>LAW AND JURISDICTION</p>

            <br>

            <p>These terms of use will be governed by and construed in accordance with South African law, and any
              disputes relating to these terms of use will be subject to the non-exclusive jurisdiction of the courts
              of South Africa.</p>

            <br>

            <p>REGISTRATIONS AND AUTHORISATIONS</p>

            <br>

            <p>Oxia DM (Pty) Ltd, is registered with the Commissioner of Companies & Intellectual Property Commission
              in the Republic of South Africa. Our registration number is 2000/018740/21. The full name of our
              business is Zoran Aleksic Inc.</p>

            <br>

            <p>Our current business address is 53 Canterbury Drive, Bishopscourt, Cape Town 7780. You may contact us
              by e-mailing legal@oxia.org.</p>

            <br>

            <p>OVERVIEW</p>

            <br>

            <p>We are committed to safeguarding the privacy of our website visitors; this policy sets out how we will
              treat your personal information.</p>

            <br>

            <p>Our website uses cookies. By using our website and agreeing to this policy, you consent to our use of
              cookies in accordance with the terms of this policy.</p>

            <br>

            <p>WHAT INFORMATION DO WE COLLECT?</p>

            <br>

            <p>We may collect, store and use the following kinds of personal information:</p>

            <br>

            <ul class="terms-and-conditions-page-list">
              <li>Information about your computer and about your visits to and use of this website (including your IP
                address, geographical location, browser type and version, operating system, referral source, length of
                visit, page views, website navigation);</li>
              <li>Information relating to any transactions carried out between you and us on or in relation to this
                website;</li>
              <li>Information that you provide to us for the purpose of registering an online account with us;</li>
              <li>Information that you provide to us for the purpose of filling forms, including “contact us” forms
                and emails;</li>
              <li>Any other information that you choose to send to us;</li>
            </ul>

            <p>Before you disclose to us the personal information of another person, you must obtain that person’s
              consent to both the disclosure and the processing of that personal information in accordance with the
              terms of this privacy policy.</p>

            <br>

            <p>COOKIES</p>

            <br>

            <p>A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web
              server to a web browser and is stored by the browser. The identifier is then sent back to the server
              each time the browser requests a page from the server. This enables the web server to identify and track
              the web browser. We may use both such “session” cookies and “persistent” cookies on the website. Session
              cookies will be deleted from your computer when you close your browser. Persistent cookies will remain
              stored on your computer until deleted, or until they reach a specified expiry date.</p>

            <br>

            <p>We will use the session cookies to: keep track of you whilst you navigate the website and prevent fraud
              and increase website security. We will use the persistent cookies to: enable our website to recognise
              you when you visit; keep track of your preferences in relation to your use of our website.</p>

            <br>

            <p>We use “Google Analytics” and “Visitor Analytics” to analyse the use of this website. Both “Google
              Analytics” and “Visitor Analytics” generates statistical and other information about website use by
              means of cookies, which are stored on users’ computers. The information generated relating to our
              website is used to create reports about the use of the website.</p>

            <br>

            <p>USING YOUR PERSONAL INFORMATION</p>

            <br>

            <p>Personal information submitted to us via this website will be used for the purposes specified in this
              privacy policy or in relevant parts of the website.</p>

            <br>

            <p>We may use your personal information to:</p>

            <br>

            <ul class="terms-and-conditions-page-list">
              <li>Enable your use of the services available on the website;</li>
              <li>Send you email notifications which you have specifically requested;</li>
              <li>Send you our newsletter;</li>
              <li>Deal with enquiries and complaints made by you;</li>
              <li>Keep the website secure and prevent fraud;</li>
            </ul>

            <p>We will not intentionally, without your express consent, provide your personal information to any third
              parties for the purpose of direct marketing.</p>

            <br>

            <p>DISCLOSURES</p>

            <br>

            <p>We may disclose your personal information to any of our security officers, managing agent/s, agents,
              suppliers or subcontractors insofar as reasonably necessary for the purposes set out in this privacy
              policy.</p>

            <br>

            <p>In addition, we may disclose your personal information:</p>

            <br>

            <ul class="terms-and-conditions-page-list">
              <li>To the extent that we are required to do so by law;</li>
              <li>In connection with any ongoing or prospective legal proceedings;</li>
              <li>In order to establish, exercise, or defend our legal rights (including providing information to
                others for the purposes of fraud prevention);</li>
              <li>To any person who we reasonably believe may apply to a court or other competent authority for
                disclosure of that personal information where, in our reasonable opinion, such court or authority
                would be reasonably likely to order disclosure of that personal information.</li>
            </ul>


            <p>Except as provided in this privacy policy, we will not provide your information to third parties not
              affiliated with Oxia DM (Pty) Ltd.</p>

            <br>

            <p>INTERNATIONAL DATA TRANSFERS</p>

            <br>

            <p>Information that we collect may be stored and processed in and transferred between any of the countries
              in which we operate in order to enable us to use the information in accordance with this privacy policy.
            </p>

            <br>

            <p>In addition, personal information that you submit for publication on the website will be published on
              the internet and may be available, via the internet, around the world. We cannot prevent the use or
              misuse of such information by others.</p>

            <br>

            <p>You expressly agree to such transfers of personal information.</p>

            <br>

            <p>SECURITY OF YOUR PERSONAL INFORMATION</p>

            <br>

            <p>We will take reasonable technical and organisational precautions to prevent the loss, misuse or
              alteration of your personal information.</p>

            <br>

            <p>We will store all the personal information you provide on our secure (password- and firewall-protected)
              servers.</p>

            <br>

            <p>You acknowledge that the transmission of information over the internet is inherently insecure, and we
              cannot guarantee the security of data sent over the internet.</p>

            <br>

            <p>You are responsible for keeping your password [and other login details] confidential. We will not ask
              you for your password (except when you log in to the website).</p>

            <br>

            <p>POLICY AMENDMENTS</p>

            <br>

            <p>We may update this privacy policy from time to time by posting a new version on our website. You should
              check this page occasionally to ensure you are happy with any changes.</p>

            <br>

            <p>We may also notify you of changes to our privacy policy by email.</p>

            <br>

            <p>YOUR RIGHTS</p>

            <br>

            <p>You may instruct us to provide you with any personal information we hold about you. Provision of such
              information will be subject to:</p>

            <br>

            <p>the payment of a admin fee; and</p>

            <br>

            <p>the supply of appropriate evidence of your identity.</p>

            <br>

            <p>We may withhold such personal information to the extent permitted by law.</p>

            <br>

            <p>THIRD PARTY WEBSITES</p>

            <br>

            <p>The website contains links to other websites. We are not responsible for the privacy policies or
              practices of third party websites. Oxia DM (Pty) Ltd does not necessarily agree or affiliate with third
              party believes & practices and that they stand as a registered organisation in itself.</p>

            <br>

            <p>UPDATING INFORMATION</p>

            <br>

            <p>Please let us know if the personal information which we hold about you needs to be corrected or
              updated.</p>

            <br>

            <p>ELECTRONIC COMMUNICATIONS</p>

            <br>

            <p>When you visit Oxia DM (Pty) Ltd or send e-mails to us, you are communicating with us electronically.
              You consent to receive communications from us electronically. We will communicate with you by e-mail.
              You agree that all agreements, notices, disclosures and other communications that we provide to you
              electronically satisfy any legal requirement that such communications be in writing. Visitors may by
              electronic mail (e-mail) submit suggestions, ideas, comments, questions, or other information, so long
              as the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of
              intellectual property rights, or otherwise injurious to third parties or objectionable and does not
              consist of or contain software viruses, political campaigning, commercial solicitation, chain letters,
              mass mailings, or any form of "spam." You may not use a false e-mail address, impersonate any person or
              entity, or otherwise mislead as to the origin of a card or other content. You represent and warrant that
              you own or otherwise control all of the rights to the content that you post: that the content is
              accurate: that use of the content you supply does not violate this policy and will not cause injury to
              any person or entity: and that you will indemnify Oxia DM (Pty) Ltd or its associates for all claims
              resulting from content you supply. Oxia DM (Pty) Ltd has the right but not the obligation to monitor and
              edit or remove any activity or content. Oxia DM (Pty) Ltd takes no responsibility and assumes no
              liability for any content posted by you or any third party.</p>

            <br>

            <p>COPYRIGHT</p>

            <br>
            <p>All content included on this site, such as text, graphics, logos, button icons, images, audio clips,
              digital downloads, data compilations, and software, is the property of Oxia DM (Pty) Ltd or its original
              content suppliers and protected by international copyright laws. The compilation of all content on this
              site is the exclusive property of Oxia DM (Pty) Ltd, with copyright authorship for this collection by
              Oxia DM (Pty) Ltd and protected by international copyright laws.</p>

            <br>

            <p class="text-center">END</p>
          </div>
          <div class="terms-and-conditions-page-footer">
            <h1 class='terms-and-conditions-page-footer-text'>Terms & Conditions of Using our Platform</h1>
          </div>
          <div class="pdf-icon-container">
            <div class="pdf-item">
              <img src="/images/PdfIcon.png" class="pdf-icon" />
              <h2 class="pdf-text">PDF</h2>
            </div>
            <div class="pdf-item">
              <img src="/images/PdfIcon.png" class="pdf-icon" />
              <h2 class="pdf-text">PDF</h2>
            </div>
          </div>
        </div>
      </div>
    </OModal>
  </footer>
</template>

<script setup>
import { ref } from 'vue';

const isModalActive = ref(false);

const showModal = () => {
  isModalActive.value = true;
}
const openMailTo = () => {
  window.location.href = "mailto:support@oxia.org";
}

defineExpose({
  showModal,
  isModalActive
})
</script>

<style scoped></style>